import React, { useEffect, useState } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Table, Button, Dropdown, Skeleton, Empty, Menu, Descriptions, Typography, Drawer, Row, Col, message
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { RollbackOutlined, DownOutlined } from '@ant-design/icons';
// import styles from "./listproduct.module.css";
import { axiosClient } from '../../apiClient';
import { mascaraCnpj, mascaraCpf } from '../../functions/validation';

const { Link } = Typography;

export default function SellerListOrderDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const sellerData = useSelector((state) => state.sellerData);
  const [deleteDrawerVisible, setDeleteDrawerVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [prodData, setProdData] = useState([]);
  const [orderData, setOrderData] = useState(false);

  const { orderId } = useParams();
  const pathSegment = location.pathname.split('/').slice(-2)[0];

  const orderTypeTitle = pathSegment === 'orcamentos' ? 'Orçamento' : 'Pedido'

  useEffect(() => {
    axiosClient
      .post(
        '/cart/get-order',
        { orderId: orderId },
        { withCredentials: true },
      )
      .then((response) => {
        const { order } = response.data;
        const { products } = order.order_info;
        const { quantities } = order.order_info;
        const { prices } = order.order_info;

        const prodData = products.map((obj, index) => ({
          ...obj,
          key: index,
          sellPrice: prices[index],
          quantity: quantities[index],
        }));
        setProdData(prodData);
        setOrderData(order);
        setLoading(false);
      });
  }, []);

  function deleteOrder() {
    setSending(true);
    axiosClient
      .post(
        '/cart/delete-order',
        { orderId: orderId },
        { withCredentials: true },
      )
      .then((response) => {
        setSending(false);
        navigate(`/admin/vendas/${pathSegment}`);
        // dispatch({ type: "LIST_ORDER" });
      });
  }

  const columns = [
    {
      title: 'Produto',
      dataIndex: 'product_name',
      key: 'name',
      render: (text, record) => (
        <Link>
          {' '}
          {text}
          {' '}
        </Link>
      ),
      ellipsis: true,
      responsive: ['sm'],
      width: '40%',
    },
    {
      title: 'Código',
      dataIndex: 'product_sku',
      key: 'preco',
      responsive: ['sm'],
      align: 'center',
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantity',
      key: 'quantity',
      responsive: ['sm'],
      align: 'center',
    },
    {
      title: 'Preço',
      dataIndex: 'sellPrice',
      key: 'sellPrice',
      render: (text) => <>{`R$ ${parseFloat(text).toFixed(2)}`}</>,
      responsive: ['sm'],
      align: 'center',
    },
    {
      title: 'Total',
      // dataIndex: "sellTotal",
      key: 'sellTotal',
      render: (record) => <>{`R$ ${parseFloat(parseFloat(record.sellPrice) * parseInt(record.quantity)).toFixed(2)}`}</>,
      responsive: ['sm'],
      align: 'center',
    },
    {
      title: 'Produtos',
      render: (record) => (
        <>
          <Link>
            {' '}
            {record.product_name}
            {' '}
          </Link>
          <br />
          {`Preço: R$ ${parseFloat(record.sellPrice).toFixed(2)}`}
          <br />
          {`Quantidade: ${parseInt(record.quantity)}`}
          <br />
          {`Total: R$ ${parseFloat(parseFloat(record.sellPrice) * parseInt(record.quantity)).toFixed(2)}`}
        </>
      ),
      responsive: ['xs'],
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setDeleteDrawerVisible(true)} key="1">Excluir {orderTypeTitle}</Menu.Item>
      <Menu.Item onClick={() => navigate(`/${sellerData.username}/${pathSegment}/${orderId}`)} key="2">Editar {orderTypeTitle}</Menu.Item>
      {pathSegment == "orcamentos" && <Menu.Item onClick={() => {
        axiosClient.post(
          '/cart/quote-to-order',
          { orderId: orderId },
          { withCredentials: true },
        )
          .then((response) => {
            message.success('Pedido criado com sucesso!', 4)
            navigate(`/admin/vendas/pedidos/${orderId}`);
          })
      }} key="3">Finalizar como pedido</Menu.Item>}
    </Menu>
  );

  function DeleteDrawer() {
    return <Drawer
      title="Confirmar Exclusão"
      placement="bottom"
      onClose={() => setDeleteDrawerVisible(false)}
      open={deleteDrawerVisible}
      height={200}
    >
      <Row justify="center">
        <Col>
          <Typography.Title style={{ paddingBottom: 8, margin: 0 }} level={5}>
            Você tem certeza que deseja excluir este {orderTypeTitle}?
          </Typography.Title>
        </Col>
      </Row>
      <Row justify="center" gutter={24}>
        <Col>
          <Button onClick={() => setDeleteDrawerVisible(false)}>Cancelar</Button>
        </Col>
        <Col>
          <Button type="primary" danger onClick={() => {
            deleteOrder() && navigate(`/admin/vendas/${pathSegment}`)
          }}>
            Excluir
          </Button>
        </Col>
      </Row>
    </Drawer>
  }

  return (
    <>
      <PageHeader
        title={`${orderTypeTitle} Nº ${orderData && orderData.number ? orderData.number : ''}`}
        onBack={() => navigate(`/admin/vendas/${pathSegment}`)}
        extra={
          orderId && (
            <Dropdown overlay={menu} trigger={['click']}>
              <Button type="primary" loading={sending}>
                Ações
                {' '}
                <DownOutlined />
              </Button>
            </Dropdown>
          )
        }
      />
      {orderData && (
        <Descriptions layout="vertical" bordered>
          <Descriptions.Item label="Cliente">
            <Link>
              {orderData.buyer.tipo_pessoa === 'pf'
                ? orderData.buyer.nome
                : orderData.buyer.razao_social}
            </Link>
          </Descriptions.Item>
          <Descriptions.Item
            label={orderData.buyer.tipo_pessoa === 'pf' ? 'CPF' : 'CNPJ'}
          >
            {orderData.buyer.tipo_pessoa === 'pf'
              ? mascaraCpf(orderData.buyer.cpf)
              : mascaraCnpj(orderData.buyer.cnpj)}
          </Descriptions.Item>
          <Descriptions.Item label="Método de Entrega">
            {orderData.shipping_info.nome}
          </Descriptions.Item>
          <Descriptions.Item label="Método de Pagamento">
            {orderData.payment_info.descricao}
          </Descriptions.Item>
        </Descriptions>
      )}
      <Table
        columns={columns}
        expandIconColumnIndex={6}
        style={{ cursor: 'pointer' }}
        // rowSelection={"checkbox"}
        expandable={{
          expandedRowRender: (record) => (
            <>
              {
                record.gtin
                  ? `GTIN: ${record.gtin}`
                  : 'GTIN: Não informado'
              }
            </>
          ),
          rowExpandable: (record) => record.name !== 'Not Expandable',
        }}
        dataSource={loading ? [] : prodData}
        locale={{
          emptyText: loading ? <Skeleton active /> : <Empty />,
        }}
      />
      {orderData && (
        <>
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item label="Total de Produtos">
              {parseFloat(orderData.total_before).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Descriptions.Item>
            <Descriptions.Item label="Taxa de entrega">
              {parseFloat(orderData.shipping_info.valor).toLocaleString(
                'pt-BR',
                { style: 'currency', currency: 'BRL' },
              )}
            </Descriptions.Item>
            {orderData.discount !== 0 && (
              <Descriptions.Item label="Descontos">
                {parseFloat(orderData.discount).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Total">
              {parseFloat(orderData.total).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Descriptions.Item>
          </Descriptions>
          {orderData.observations && (
            <Descriptions
              layout="vertical"
              bordered
              style={{ marginTop: '15px' }}
            >
              <Descriptions.Item label="Observações">
                {orderData.observations}
              </Descriptions.Item>
            </Descriptions>
          )}
        </>
      )}
      <Button
        style={{ marginTop: '15px' }}
        onClick={() => {
          navigate(`/admin/vendas/${pathSegment}`);
        }}
      >
        <RollbackOutlined />
        {' '}
        Voltar
      </Button>
      <DeleteDrawer />
    </>
  );
}
